function createPlatonicScoreTable() {
    const platonicScoreTable: number[][] = [];

    for (let i = 0; i < 6; i++) {
        const row: number[] = [];

        for (let j = 0; j < 8; j++) {
            const randomNum = Math.floor(Math.random() * 10) + 1;
            row.push(randomNum);
        }

        platonicScoreTable.push(row);
    }

    return { platonicScoreTable };
}

export const { platonicScoreTable } = createPlatonicScoreTable();