import { useContext } from "react";
import { WBBButtonActionType } from "../../types";
import { WBBControlButton } from "../Control/WBBControlButton";
import "./StatusPanel.scss";
import { StatusText } from "./StatusText";
import { GameStateContext } from "../../contexts/GameStateContext";

export const StatusPanel = () => {
    const gameState = useContext(GameStateContext);
    return (
        <section className="status-panel">
            <StatusText />
            {gameState.end ? (
                null
            ) : (
                <WBBControlButton label={"Finalize Arrangement"} actionType={WBBButtonActionType.FINALIZE} payload={[1,2,3,4,5,6]} />
            )}
        </section>
    );
};