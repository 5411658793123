import { WBBDiningRoomProps } from "../types";

export function setComplaintFlags(diningRoom: WBBDiningRoomProps, turnNumber: number) {
    if (turnNumber % 13 === 1) {
        diningRoom.tables.forEach((table) => {
            table.seats.forEach((seat) => {
                const seatedGuest = seat.seatedGuest;

                if (seatedGuest && (seatedGuest.type === 'M1' || seatedGuest.type === 'C1')) {
                    const matchingGuest = table.seats.find((otherSeat) => {
                        const otherGuest = otherSeat.seatedGuest;
                        return otherGuest && otherGuest.type === seatedGuest.type && otherGuest !== seatedGuest;
                    });

                seatedGuest.complaintFlag = !matchingGuest;
                diningRoom.splitCoupleComment = (matchingGuest ? "" : `${seatedGuest.name} is seated at table ${table.tableNum} seat ${seat.tableSeatNum} and is not seated with their partner.`)
                }
            });
        });
    } else {
        diningRoom.splitCoupleComment = "";
    }
}