type OpenSeatMapping = {
    [option: number]: Array<{
        [tableNum: number]: number;
    }>;
};

export const openSeatMapping: OpenSeatMapping = {
    1: [
        {
            2: 5,
        },
        {
            3: 1,
        },
        {
            3: 2,
        },
        {
            4: 6,
        },
        {
            5: 3,
        },
        {
            5: 4,
        },
    ],
    2: [
        {
            2: 6,
        },
        {
            6: 4,
        },
        {
            6: 5,
        },
        {
            8: 1,
        },
        {
            8: 2,
        },
        {
            8: 3,
        },
    ],
    3: [
        {
            3: 1,
        },
        {
            3: 2,
        },
        {
            5: 3,
        },
        {
            5: 4,
        },
        {
            6: 5,
        },
        {
            6: 6,
        },
    ],
    4: [
        {
            2: 1,
        },
        {
            2: 2,
        },
        {
            4: 3,
        },
        {
            5: 4,
        },
        {
            7: 5,
        },
        {
            8: 6,
        },
    ],
}