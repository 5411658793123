import { WBBButtonActionType, WBBControlButtonGroupProps } from "../types";

function setButtonGroups() {
    const buttonGroup: WBBControlButtonGroupProps = {
        buttonGroup: [
            {
                label: "Rotate",
                actionType: WBBButtonActionType.PERMUTE,
                payload: [2, 3, 4, 5, 6, 1],
            },
            {
                label: "Swap1",
                actionType: WBBButtonActionType.PERMUTE,
                payload: [2, 1, 3, 4, 5, 6],
            },
            {
                label: "Swap2",
                actionType: WBBButtonActionType.PERMUTE,
                payload: [1, 2, 4, 3, 5, 6],
            },
            {
                label: "Swap3",
                actionType: WBBButtonActionType.PERMUTE,
                payload: [1, 3, 2, 4, 5, 6],
            },
            {
                label: "Swap4",
                actionType: WBBButtonActionType.PERMUTE,
                payload: [1, 2, 3, 5, 4, 6],
            },
            {
                label: "Swap5",
                actionType: WBBButtonActionType.PERMUTE,
                payload: [6, 2, 3, 4, 5, 1],
            },
        ],
    }
    return { buttonGroup };
}

export const { buttonGroup } = setButtonGroups();