import { WBBGuest, WBBGuestType } from "../../types";

export const guestList: WBBGuest[] = [
    {
        name: "Tom Davis",
        guestNum: 1,
        type: WBBGuestType.M1,
        gender: "M",
        complaintFlag: false,
    },
    {
        name: "Lana Davis",
        guestNum: 2,
        type: WBBGuestType.M1,
        gender: "F",
        complaintFlag: false,
    },
    {
        name: "Ray Suiter",
        guestNum: 3,
        type: WBBGuestType.C1,
        gender: "M",
        complaintFlag: false,
    },
    {
        name: "Lisa Smith",
        guestNum: 4,
        type: WBBGuestType.C1,
        gender: "F",
        complaintFlag: false,
    },
    {
        name: "Alf Landon",
        guestNum: 5,
        type: WBBGuestType.S1,
        gender: "M",
        complaintFlag: false,
    },
    {
        name: "Kim Roberts",
        guestNum: 6,
        type: WBBGuestType.S2,
        gender: "F",
        complaintFlag: false,
    },
];