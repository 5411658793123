import { useContext } from "react";
import "./StatusText.scss";
import { GameStateContext } from "../../contexts/GameStateContext";

export const StatusText = () => {
    const gameState = useContext(GameStateContext);
    return (
        <div className="status-container">
            <p className="turn-number">
                Minutes Left Until Reception: {gameState.maxTurns - gameState.turnNumber}
            </p>
        </div>
    );
};