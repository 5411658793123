import { buttonGroup } from "../../helpers/setButtonGroups";
import { WBBControlButton } from "./WBBControlButton";
import { WBBControlButtonProps } from "../../types";
import './WBBControlButtonGroup.scss';
import { GameStateContext } from "../../contexts/GameStateContext";
import { useContext } from "react";

export const WBBControlButtonGroup: React.FC = () => {
    const gameState = useContext(GameStateContext);
    return (
        <>
            {gameState.end ? (
                null
            ) : (
                <div className="button-group">
                    {buttonGroup.buttonGroup.map((button: WBBControlButtonProps, index: number) => (
                        <WBBControlButton key={index} {...button} />
                    ))}
                </div>
            )}
        </>
    );
};
