import { WBBSeatProps } from '../../types';
import './Seat.scss';

const Seat = ({ seat }: { seat: WBBSeatProps }) => {
    let seatStyle = {};

    if (seat.seatedGuest) {
        switch (seat.seatedGuest.type) {
            case 'M1':
                seatStyle = { backgroundColor: '#A6A' };
                break;
            case 'C1':
                seatStyle = { backgroundColor: '#6AA' };
                break;
            case 'S1':
                seatStyle = { backgroundColor: '#883' };
                break;
            case 'S2':
                seatStyle = { backgroundColor: '#999' };
                break;
            default:
                break;
        }
    }

    return (
        <div className="seat" style={seatStyle}>{seat.seatedGuest?.name} {seat.seatedGuest?.complaintFlag ? "split flag" : ""}</div>
    );
};

export default Seat;