function createRomanticScoreTable() {
    const romanticScoreTable: number[][] = [];

    for (let i = 0; i < 6; i++) {
        const row: number[] = [];

        for (let j = 0; j < 8; j++) {
            const randomNum = Math.random();

            if (randomNum < 0.5) {
                row.push(0);
            } else {
                row.push(1);
            }
        }

        romanticScoreTable.push(row);
    }

    return { romanticScoreTable };
}

export const { romanticScoreTable } = createRomanticScoreTable();