import multiplyPermutations from "@wbb-game/processing-module/helpers/multiplyPermutations";
import { WBBGameState, WBBGameStateAction } from "../types";

function gameStateReducer(state: WBBGameState, action: WBBGameStateAction) {
        if (action.type === "permute") {
            const newPermutation = multiplyPermutations(
                state.currentState,
                action.payload
            );

            const newTurnNumber = state.turnNumber + 1;
            if (newTurnNumber === state.maxTurns) {
                return {
                    ...state,
                    currentState: newPermutation,
                    turnNumber: newTurnNumber,
                    end: true,
                };
            };

            return {
                ...state,
                currentState: newPermutation,
                turnNumber: newTurnNumber,
            };
        }
        if (action.type === "finalize") {
            return {
                ...state,
                end: true,
            };
        }
        return { ...state, };
}

export { gameStateReducer };