import { WBBDiningRoomProps, WBBDiningTableProps, WBBSeatProps } from "../types";
import { guestList } from './constants/guestList';
import { openSeatMapping } from './constants/openSeatMapping';
import { getRandomSeatedGuest } from "./getRandomGuest";
import { setComplaintFlags } from "./setComplaintFlags";
import { setTableComment } from "./setTableComment";

export function setDiningRoom(currentPermutation: number[], openSeatMapOption: number, turnNumber: number) {
    const diningRoom: WBBDiningRoomProps = {
        tables: Array.from({ length: 8 }, (_, tableIndex) => {
            const table: WBBDiningTableProps = {
                tableNum: tableIndex + 1,
                seats: Array.from({ length: 8 }, (_, seatIndex) => {
                    let seat: WBBSeatProps = {
                        tableSeatNum: seatIndex + 1,
                    };

                    openSeatMapping[openSeatMapOption].forEach((seatMap) => {
                        if (seatMap[tableIndex + 1] === seatIndex + 1) {
                            seat = {
                                tableSeatNum: seatIndex + 1,
                                seatedGuest: guestList[currentPermutation[seatIndex] - 1],
                            };
                        }
                    });
                    return seat;
                }),
            };
            table.comment = "";
            return table;
        }),
    };

    setComplaintFlags(diningRoom, turnNumber);
    const { seatedGuest, seat, table } = getRandomSeatedGuest(diningRoom);
    const tableComment = setTableComment(seatedGuest, seat, table);
    diningRoom.tables[table.tableNum - 1].comment = tableComment;

    return { diningRoom };
}