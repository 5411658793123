import { WBBDiningTableProps } from '../../types';
import './DiningTable.scss';
import Seat from './Seat';

export const DiningTable = ({ table }: { table: WBBDiningTableProps }) => {
    return (
        <div className="table-container">
            <div className="table">
                {table.seats.map((seat, i) => (
                    <Seat key={i} seat={seat} />
                ))}
                <p className="hint-comment">{table.comment}</p>
            </div>
        </div>
    );
};