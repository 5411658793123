import "./Game.scss";
import { HomepageLink } from "./Display/HomepageLink";
import { DiningRoom } from "./Display/DiningRoom";
import { WBBControlButtonGroup } from "./Control/WBBControlButtonGroup";
import { gameStateReducer } from "../reducers/gameStateReducer";
import { initialGameState } from "../helpers/initializeGameState";
import { useReducer } from "react";
import { StatusPanel } from "./Display/StatusPanel";
import { GameStateContext, GameStateDispatchContext } from "../contexts/GameStateContext";

export const Game = () => {
    const [gameState, dispatch] = useReducer(
        gameStateReducer,
        initialGameState
    )
    
    return (
        <GameStateContext.Provider value={gameState}>
            <GameStateDispatchContext.Provider value={dispatch}>
                <div className="wbb-game">
                    <HomepageLink />
                    <StatusPanel />
                    <DiningRoom />
                    <WBBControlButtonGroup />
                    {gameState.end ? (
                        <div className="end-game">
                            <p>Game Over</p>
                        </div>
                    ) : null}
                </div>
            </GameStateDispatchContext.Provider>
        </GameStateContext.Provider>
    );
};
