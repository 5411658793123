import { GameStateContext } from '../../contexts/GameStateContext';
import { setDiningRoom } from '../../helpers/setDiningRoom';
import './DiningRoom.scss';
import { DiningTable } from './DiningTable';
import { useContext } from 'react';

export const DiningRoom = () => {
    const gameState = useContext(GameStateContext);
    const { diningRoom } = setDiningRoom(gameState.currentState, gameState.openSeatMapOption, gameState.turnNumber);
    
    return (
        <div className="dining-room">
            {diningRoom.tables.map((table, i) => (
                <DiningTable key={i} table={table} />
            ))}
            {diningRoom.splitCoupleComment !== "" && (
                <div className="split-couple-comment">
                    {diningRoom.splitCoupleComment}
                </div>
            )}
        </div>

    );
};