import { WBBGameState } from "../types";
import { romanticScoreTable } from "./setRomanticScoreTable";
import { platonicScoreTable } from "./setPlatonicScoreTable";

function initializeGameState() {
    const initialGameState: WBBGameState = {
        currentState: [1, 2, 3, 4, 5, 6],
        openSeatMapOption: Math.floor(Math.random() * 4) + 1,
        turnNumber: 0,
        maxTurns: 60,
        platonicScoringArray: platonicScoreTable,
        romanticScoringArray: romanticScoreTable,
        end: false,
    }

    return { initialGameState };
}

export const { initialGameState } = initializeGameState();
