import { WBBDiningRoomProps } from "../types";

export function getRandomSeatedGuest(diningRoom: WBBDiningRoomProps) {
    while (true) {
        const randomTable = diningRoom.tables[Math.floor(Math.random() * diningRoom.tables.length)];
        const randomSeat = randomTable.seats[Math.floor(Math.random() * randomTable.seats.length)];

        if (randomSeat.seatedGuest) {

            return { seatedGuest: randomSeat.seatedGuest, seat: randomSeat, table: randomTable };
        }
    }
}