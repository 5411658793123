import { GameStateDispatchContext } from '../../contexts/GameStateContext';
import { WBBControlButtonProps } from '../../types';
import { useContext } from 'react';

export const WBBControlButton: React.FC<WBBControlButtonProps> = ({ label, actionType, payload }) => {
    const dispatch = useContext(GameStateDispatchContext);

    const handleClick = () => {
        dispatch({ type: actionType, payload });
    };

    return (
        <button onClick={handleClick}>
            {label}
        </button>
    );
};