import { ControlButtonProps } from "@wbb-game/control-module/types";
import { GameState } from "@wbb-game/processing-module/types";

// Control
export enum WBBButtonActionType {
    PERMUTE = "permute",
    FINALIZE = "finalize",
}

export interface WBBControlButtonProps extends ControlButtonProps {
    label: string;
    actionType: WBBButtonActionType;
    payload: number[];
}

export interface WBBControlButtonGroupProps {
    buttonGroup: WBBControlButtonProps[];
}

export interface WBBControlButtonContainerProps {}

// Display
export enum WBBGuestType {
    M1 = "M1",
    C1 = "C1",
    S1 = "S1",
    S2 = "S2",
    GEN = "GEN",
}

export interface WBBGuest {
    name: string;
    guestNum: number;
    type: WBBGuestType;
    gender: string;
    complaintFlag: boolean;
}

export interface WBBSeatProps {
    tableSeatNum: number;
    seatedGuest?: WBBGuest;
}

export interface WBBDiningTableProps {
    tableNum: number;
    seats: WBBSeatProps[];
    comment?: string;
}

export interface WBBDiningRoomProps {
    tables: WBBDiningTableProps[];
    splitCoupleComment?: string;
}

// Processing
export interface WBBGameState extends GameState {
    currentState: number[];
    openSeatMapOption: number;
    turnNumber: number;
    maxTurns: number;
    platonicScoringArray: number[][];
    romanticScoringArray: number[][];
    end: boolean;
}

export interface WBBGameStateAction {
    type: WBBButtonActionType;
    payload: number[];
}