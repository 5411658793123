import { WBBDiningTableProps, WBBGuest, WBBSeatProps } from "../types";
import { romanticScoreTable } from "./setRomanticScoreTable";
import { platonicScoreTable } from "./setPlatonicScoreTable";

export function setTableComment(seatedGuest: WBBGuest, seat: WBBSeatProps, table: WBBDiningTableProps) {
  const gType = seatedGuest.type;
  const gGender = seatedGuest.gender;
  const hasRomanticEffect = romanticScoreTable[seatedGuest.guestNum - 1][table.tableNum - 1];
  const platonicEffect = (platonicScoreTable[seatedGuest.guestNum - 1][table.tableNum - 1] ? platonicScoreTable[seatedGuest.guestNum - 1][table.tableNum - 1] : 0);
  const platonicFrictionAdj = (platonicEffect > 5 ? -2 : 2);
  
  let modifiedPlatonicEffect: number = platonicEffect;

  table.seats.forEach((seat) => {
    if (seatedGuest && (seatedGuest.type === 'M1' || seatedGuest.type === 'C1')) {
      const matchingGuest = table.seats.find((otherSeat) => {
        const otherGuest = otherSeat.seatedGuest;
        return otherGuest && otherGuest.type === seatedGuest.type && otherGuest !== seatedGuest;
      });

      modifiedPlatonicEffect = Math.min(10, Math.max(1, platonicEffect - (matchingGuest ? 0 : platonicFrictionAdj)));
    }
  });

  const tableComment = `${seatedGuest.name} is seated at table ${table.tableNum} seat ${seat.tableSeatNum}. The guest has status ${gType[0]}, gender ${gGender}, has ${hasRomanticEffect ? " no " : ""} romantic entanglements and a friction level of ${modifiedPlatonicEffect} (base ${platonicEffect}).`;
  return tableComment;
}
